<subject-duo-choice>
    <div class="ui four column padded grid">
        <div class="column" each="{ posssibility in posssibilities }">
            <div class="ui special card">
                <div class="blurring dimmable image">
                    <div class="ui inverted dimmer">
                      <div class="content">
                        <div class="center">
                          <button class="ui primary button" value="{ posssibility.id }"
                          onclick={ clickHandler }>Démarrer</button>
                        </div>
                      </div>
                    </div>
                    <img src="https://fomantic-ui.com/images/avatar2/large/kristy.png">
                  </div>
                <div class="content title-container">
                    <div class="center">
                        <p>{ posssibility.name ? posssibility.name : posssibility.id }</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <script>
        export default {
            onBeforeMount(props, state) {
                this.posssibilities = props.posssibilities;
                this.clickHandler = props.clickHandler;
            },
            onMounted(){
                // affiche le buton démarrer du sujet
                $('.special.card .image').dimmer({
                    on: 'hover'
                });
            }
        }
    </script>
</subject-duo-choice>