<subject-cpn>
    <div class="ui center aligned container">
        <h2>Liste des sujets</h2>
    </div>
    <subject-duo-choice posssibilities="{subjects}" clickHandler="{click}"></subject-duo-choice>

    <script>
        export default {            
            onBeforeMount(props, state) {
                this.localProps = props;
                this.subjects = props.subjects;
            },
            click(e) {
                e.preventDefault();
                this.choix=e.target.value;
                this.localProps.eventBus.trigger('sujetEstChoisi', this.choix);
            }
        }
    </script>
</subject-cpn>