<orientation-app>
    <orientation-header></orientation-header>

    <resultats-cpn if={ isResults } quizResult="{ quizResult }" eventBus="{ eventBus }"></resultats-cpn>

    <subject-cpn if={ isSubject } subjects="{ subjects }" eventBus="{ eventBus }"></subject-cpn>
    <embed-cpn if={ isEmbed } question="{ question }"></embed-cpn>

    <question-cpn if={ isQuestion } question="{ question }" isPreview="{ isPreview }" eventBus="{ eventBus }"></question-cpn>

    <duos-cpn if={ isDuo } duos="{ duos }" eventBus="{ eventBus }"></duos-cpn>

    <script>
        import '../../static/styles/styles.scss'
        import { importJson, getSubjectsWithQuestions, getDuosbySubject, getQuestionsBySubjectOrDuo, getDisciplinesScoreObject, getDuo, getQuestionById } from '/src/static/js/json-repository';
        import {getResults, saveResults, resetResults} from '/src/static/js/web-storage';
        import _ from 'underscore';
        //import all image in folder images, false is for recursivity
        require.context('../../static/images/', false, /\.(png|jpe?g|gif)$/i)

        function eventBus(exportDefault) {
            observable(this);

            this.on('sujetEstChoisi', function (subjectId) {
                exportDefault.loadSubjectQuestions(subjectId);
            });

            this.on('response', function (question) {

                exportDefault.resultats.push(question);
                
                saveResults(exportDefault.localStorageInfos.results.key, exportDefault.resultats);
                // Question type sujet
                if (question.subject != null) {
                    if (exportDefault.numQuestion == exportDefault.nbQuiz) {
                        
                        exportDefault.finishSubjectTest();
                    }
                    else {
                        if(!(question.subject in exportDefault.disciplineScores)) exportDefault.disciplineScores[question.subject] = getDisciplinesScoreObject(exportDefault.json)
                        _.each(question.disciplinesCible, (discipline) => {
                            exportDefault.disciplineScores[question.subject][discipline].max += 1;
                            if (question.correct) {
                                exportDefault.disciplineScores[question.subject][discipline].score += 1;
                            }                 
                        });
                        saveResults(exportDefault.localStorageInfos.disciplineScores.key, exportDefault.disciplineScores);
                        exportDefault.loadSubjectQuestions(exportDefault.question.subject);
                    }
                }
                // Question type discipline
                else if (question.discipline != null) {
                    if (exportDefault.numQuestion == exportDefault.nbQuiz) {
                        exportDefault.finishDuoTest();
                    }
                    else {
                        exportDefault.loadDisciplineQuestions(exportDefault.question.duo);
                    }
                }
            });

            this.on('duoEstChoisi', function (disciplineId) {
                exportDefault.loadDisciplineQuestions(disciplineId);
            });

            this.on('retourSujet',function () {
                exportDefault.loadSubject();
            });

            this.on('goToDuo',function () {
                exportDefault.loadDuo(exportDefault.lastSubject);
            });

            this.on('previewQuestion', function(questionId) {
                exportDefault.loadQuestionPreview(questionId);
            });
        }

        export default {
            loadSubject() {
                this.isSubject = true;
                this.isEmbed = this.isQuestion = this.isDuo = this.isResults = this.isPreview = false;
                this.subjects = getSubjectsWithQuestions(this.json);

                this.update();
            },
            loadResult(result) {
                if (this.resultats != null) {
                    this.isEmbed = this.isQuestion = this.isDuo = this.isPreview = false;
                    this.isResults = true;
                    this.quizResult = result;
                }
                this.update();
            },
            loadResultPreview(){
                this.isEmbed = this.isQuestion = this.isDuo = this.isPreview = false;
                this.isResults = true;
                this.quizResult = [
                    {id: 'test1', percent: 0.75, score: 75, max: 100},
                    {id: 'test2', percent: 0.20, score: 20, max: 100},
                ];
                this.update();
            },
            finishSubjectTest(){
                this.numQuestion = this.nbQuiz = 0;
                this.loadDuo(this.lastSubject, this.disciplineScores[this.lastSubject]);
            },
            finishDuoTest(){
                this.numQuestion = this.nbQuiz = 0;
                
                const duoResponse = this.getDuoScore();
                this.lastDuo = null;
                this.loadResult(duoResponse);
            },
            loadSubjectQuestions(subjectId) {
                this.isSubject = this.isDuo = this.isResults = this.isPreview = false;
                this.isEmbed = true;

                if (this.lastSubject === undefined || this.lastSubject != subjectId) {
                    this.questions = getQuestionsBySubjectOrDuo(this.json, this.resultats, subjectId, null);
                    this.nbQuiz = this.questions.length;
                    this.lastSubject = subjectId;
                }
                if (this.nbQuiz == 0){
                    this.finishSubjectTest();    
                } else {
                    this.question = this.questions[this.numQuestion];
    
                    // Pour décocher les bouttons radio
                    this.isQuestion = false;
    
                    this.update();
    
                    this.isQuestion = true;
                    this.numQuestion++;
                    this.update();
                }
            },
            loadDisciplineQuestions(duoId) {
                this.isSubject = this.isDuo = this.isResults = this.isPreview = false;

                if (this.lastDuo === undefined || this.lastDuo != duoId) {
                    this.questions = getQuestionsBySubjectOrDuo(this.json, this.resultats, null, duoId);
                    this.nbQuiz = this.questions.length;
                    this.lastDuo = duoId;
                }
                if (this.nbQuiz == 0){
                    this.finishDuoTest();
                } else {
                    this.question = this.questions[this.numQuestion];
    
                    // Pour décocher les bouttons radio
                    this.isQuestion = false;
                    this.update();
    
                    this.isQuestion = true;
                    this.numQuestion++;
                    this.update();
                }
            },
            loadQuestionPreview(questionId){
                this.isSubject = this.isDuo = this.isResults = false;
                this.isQuestion = true;
                this.isPreview = true
                this.question = getQuestionById(this.json, questionId);
                if(this.question) {
                    this.update();
                }else {
                    this.loadSubject();
                }
            },
            sortDuo(subject, rawDuos) {
                const scores = [];
                _.each(rawDuos, (duo) => {
                    const duoScore = this.quizResult[duo.disciplines[0]].score + this.quizResult[duo.disciplines[1]].score;
                    const duoMax = this.quizResult[duo.disciplines[0]].max + this.quizResult[duo.disciplines[1]].max;
                    scores.push({
                        id : duo.id,
                        percent : duoScore / duoMax,
                        score : duoScore,
                    });
                });
                const sortDuo = scores.sort(function (a, b) {
                    return  b.percent - a.percent || b.score - a.percent;
                });
                return sortDuo;
            },
            getDuoScore(){
                const duo = getDuo(this.json, this.lastDuo);
                const firstDisciplineResponse = _.where(this.resultats, {discipline : duo.disciplines[0]});
                const secondDisciplineResponse = _.where(this.resultats, {discipline : duo.disciplines[1]});
                const scores = [
                    {id: duo.disciplines[0], percent: _.where(firstDisciplineResponse, {correct: true}).length / firstDisciplineResponse.length, score: _.where(firstDisciplineResponse, {correct: true}).length, max: firstDisciplineResponse.length},
                    {id: duo.disciplines[1], percent: _.where(secondDisciplineResponse, {correct: true}).length / secondDisciplineResponse.length, score: _.where(secondDisciplineResponse, {correct: true}).length, max: secondDisciplineResponse.length},
                ];
                return scores;
            },
            loadDuo(sujet, result) {
                this.isEmbed = this.isQuestion = this.isResults = false;
                this.isDuo = true;
                this.quizResult = result;
                const rawDuos = getDuosbySubject(this.json, sujet);
                this.duos = this.sortDuo(sujet, rawDuos);
                this.update();
            },
            onBeforeMount(props, state) {
                this.numQuestion = 0;;
                this.eventBus = new eventBus(this);
                this.json = importJson();
                this.isPreview = false;
                this.localStorageInfos = {
                    results: {key: "results", defaultValue: []},
                    disciplineScores: {key: "disciplineScores", defaultValue: {}}
                }
            },
            onMounted() {
                this.resultats = getResults(this.localStorageInfos.results.key, this.localStorageInfos.results.defaultValue);
                this.disciplineScores = getResults(this.localStorageInfos.disciplineScores.key, this.localStorageInfos.disciplineScores.defaultValue);
                this.quizResult;
                const urlParameters = new URLSearchParams(window.location.search);
                const questionId = urlParameters.get("preview");
                const resultPreview = urlParameters.get("result");
                const resetLocalStorage = urlParameters.get("reset");
                if(questionId) {
                    this.loadQuestionPreview(questionId);
                } else if(resultPreview) {
                    this.loadResultPreview();
                } else if(resetLocalStorage){
                    this.resultats = resetResults(this.localStorageInfos.results.key, this.localStorageInfos.results.defaultValue);
                    this.disciplineScores = resetResults(this.localStorageInfos.disciplineScores.key, this.localStorageInfos.disciplineScores.defaultValue);
                    location.href = "/";
                }else {
                    this.loadSubject();
                }
            }
        }
    </script>

</orientation-app>