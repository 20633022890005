<resultats-cpn class="ui text container">
    <h2>Résultats</h2>
    <p>Points obtenues aux disciplines. C'est points seront mise à jour selon les tests que vous avez passé</p>
    <div if="{sc.max != 0}" each="{sc in scores}">
        <h3>{sc.id}</h3>
        <div class="ui progress score-progress" data-value="{sc.score}" data-total="{sc.max}">
            <div class="bar">
                <div class="centered progress"></div>
            </div>
            <div class="label">{sc.score} / {sc.max}</div>
        </div>
    </div>
    <p>{reviewText}</p>

    <button class="ui button" onclick={ backSubject }>Retour aux sujets</button>

    <script>
        export default {
            onBeforeMount(props, state) {
                this.eventBus = props.eventBus;
                this.scores = props.quizResult;
                this.goodIndex = "good";
                this.mediumIndex = "medium";
                this.badIndex = "bad";
                this.getScoreReview();
            },
            onMounted(){
                $(".score-progress").progress({
                    autoSuccess : false
                });
            },
            backSubject(e){
                e.preventDefault();
                this.eventBus.trigger("retourSujet");
            },
            getScoreReview() {
                const countScore = _.countBy(this.scores, (scr) => {
                    let ret;
                    if(scr.percent >= 0.75){
                        ret = this.goodIndex;
                    } else if(scr.percent < 0.75 && scr.percent >= 0.25){
                        ret = this.mediumIndex;
                    } else {
                        ret = this.badIndex;
                    }
                    return ret;
                });
                if(this.goodIndex in countScore && countScore[this.goodIndex] == 2) {
                    this.reviewText = "Tu as les prérequis pour les deux disciplines.";
                } else {
                    if(this.badIndex in countScore && countScore[this.badIndex] == 2) {
                        this.reviewText = "Tu n'as pas les prérequis pour les deux disciplines. Il vous est conseillez de les réviser";
                    } else if(this.badIndex in countScore && countScore[this.badIndex] == 1) {
                        const badCompetrence = _.find(this.scores, (item) => item.percent < 0.25);
                        this.reviewText = "Tu n'as pas les prérequis pour la disciplines " + badCompetrence.id + ". Il vous est conseillez de la réviser";
                    } else if(this.mediumIndex in countScore && countScore[this.mediumIndex] == 2) {
                        this.reviewText = "Attention tu as a des faiblaisses dans les deux discipline. Il vous est conseillez de les réviser";
                    } else {
                        const mediumCompetence = _.find(this.scores, (item) => item.percent >= 0.25 && item.percent < 0.75);
                        this.reviewText = "Attention tu as a des faiblaisses dans la discipline" + mediumCompetence.id + ". Il vous est conseillez de la réviser";
                    }
                }
            }
        }
    </script>
</resultats-cpn>