import _ from 'underscore';

// Importe le json et retourne l'objet créé
function importJson() {
    const json = require('/src/static/assets/json/database.json');

    if (json == null) {
        throw new Error("Problème d'import du json");
    }

    return json;
}

// Retourne tous les sujets qui ont des questions
function getSubjectsWithQuestions(json) {
    return _.filter(json.subject, sujet => _.find(json.quizs, quiz => quiz.subject == sujet.id));
}

// Retourne la liste des objets duos correspondants à un sujet
function getDuosbySubject(json, subjectId) {
    var duosFiltred = [];
    var subject = _.find(json.subject, e => e.id == subjectId);

    if (subject === undefined) {
        throw new Error("Sujet " + subjectId + "non trouvé");
    }

    duosFiltred = _.filter(json.duo, duo => _.contains(subject.duos, duo.id));

    if (duosFiltred.length <= 0) {
        throw new Error("Aucun duo trouvé pour le sujet " + subjectId);
    }

    return duosFiltred;
}

function _questionIsAlreadyAsked(results, questionId){
    const quiz = _.find(results, (q) => q.id == questionId);
    return quiz? true: false;
}

function questionModel(json, item, duoId){
    let question = new Object();
    question.id = item.id;
    question.titre = item.Titre;
    question.consigne = item.Consigne;
    question.propositions = getPropositions(item.Propositions)
    question.type = item["Type d'épreuve"];
    question.bonneReponse = item["Bonnes réponses"];
    question.explication = item.Explication;
    question.illustration = item["Illustration de la consigne"];
    question.subject = item.subject;
    question.discipline = item.discipline;
    question.disciplinesCible = item["discipline cible"];
    question.duo = duoId;
    if(item.embeddedId != null) {
        question.embedded = _.filter(json.embedded, embed => embed.id == item.embeddedId)[0];
    } else {
        question.embedded = null;
    }
    return question;
}

function getQuestionsBySubjectOrDuo(json, results, subjectId, duoId) {
    var questions = new Array();
    var duo = null;
    var duoTmp = null;

    if (duoId != null) {
        duoTmp = _.where(json.duo, { id: duoId });

        if (duoTmp.length > 0) {
            duo = duoTmp[0];
        }
        else {
            throw new Error("Disciplines non trouvées pour duo " + duoId);
        }
    }

    json.quizs.forEach(quiz => {
        if (!_questionIsAlreadyAsked(results, quiz.id)){
            if ((quiz.subject != null && quiz.subject == subjectId)
                || (quiz.discipline != null && duo != null && duo.disciplines.find(e => e == quiz.discipline))) {

                const question = questionModel(json, quiz, duoId);
                questions.push(question);
            }
        }
    });
    if (duoId != null) {
        return questions;    
    }
    return _.sortBy(questions, qst => qst.embedded.id);
}

function getPropositions(propositions) {
    let tabPropositions = propositions.split('- ').filter(Boolean);
    let tabReponses = new Array();
    let i = 0;
    tabPropositions.forEach(element => {
        tabReponses.push({ id: i, text: element })
        i++;
    });

    return tabReponses;
}

function getDuo(json, duoId) {
    return _.find(json.duo, (duo) => duo.id == duoId);
}

function getDisciplinesScoreObject(json) {
    let disciplinesObject = {}
    _.each(json["discipline"], (discipline) => {
        disciplinesObject[discipline] = {score: 0 , max: 0};
    });

    return disciplinesObject;
}

function getQuestionById(json, questionId) {
    const quiz = _.find(json.quizs, (q) => q.id == questionId);
    if(quiz){
        const question = questionModel(json, quiz, null);
        return question;
    } else {
        return null;
    }
    
}

export { importJson, getSubjectsWithQuestions, getDuosbySubject, getQuestionsBySubjectOrDuo, getDisciplinesScoreObject, getDuo, getQuestionById };