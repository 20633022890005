function getResults(key, defaultValue){
    const result_raw = localStorage.getItem(key);
    if(!result_raw) {
        return defaultValue;
    }
    return JSON.parse(result_raw);
}

function saveResults(key, results){
    localStorage.setItem(key, JSON.stringify(results));
}


function resetResults(key, value){
    saveResults(key, value);
    return getResults(key, value)
}

export {getResults, saveResults, resetResults}