<embed-cpn>
    <div class="ui text container" if={this.embedded.type == "vidéo"}>
        <h2 class="header">{this.embedded.name}</h2>
        <div class="ui embed attachment" data-source="youtube" data-id="{this.embedded.url}"></div>
    </div>
    <div class="ui center aligned container" if={this.embedded.type == "podcast"}>
        <div class="attachment">
            <h2 class="header">{this.embedded.name}</h2>
            <audio controls src="{this.embedded.url}"></audio>
        </div>
    </div>

    <script>
        export default {
            loadQuestion(props, state) {
                this.embedded = props.question.embedded;
                this.doRefresh = false;
            },
            onBeforeMount(props, state) {
                this.loadQuestion(props);
            },
            onMounted(){
                $('.ui.embed').embed();
            },
            onBeforeUpdate(props, state) {
                if(props.question.embedded.url !== this.embedded.url){
                    this.loadQuestion(props);
                    $('.ui.embed').embed();
                    this.doRefresh = true;
                }
                
            },  
            onUpdated(props) {
                if(this.doRefresh){
                    $('.ui.embed').embed();
                    this.doRefresh = false;
                }
            }
        }
    </script>

</embed-cpn>