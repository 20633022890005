<question-cpn>
    <form class="ui form" onsubmit={ submit }>
        <div class="ui text container">
            <div class="ui segment">
                <raw-html class="sentence" html={question} />
                <div if="{ illustration != '' }">
                    <img src="" id="answer_illu">
                </div>
            </div>
            <div class="ui segment">
                <div class="ui error message"></div>
                <div class="grouped fields column">
                    <div each={ reponse in reponses } class="field">
                        <div class="ui { type == 'QCU' ? 'radio' : null } checkbox">
                            <input if={ !modeCorrection } id="{reponse.id}" type="{ type == 'QCU' ? 'radio' : 'checkbox' }" name="reponse" value="{reponse.id}">
                            <input if={ modeCorrection } class="{correctionData[reponse.id].class}" id="{reponse.id}" type="{ type == 'QCU' ? 'radio' : 'checkbox' }" name="reponse" value="{reponse.id}" checked="{correctionData[reponse.id].checked}" disabled>
                            <label for="{reponse.id}" class="{correctionData[reponse.id].class}">
                                <raw-html html={reponse.text} />
                            </label>
                        </div>
                    </div>
                    
                    <button if={ !modeCorrection } class="ui button" type="submit">Envoyer</button>
                    <button if={ modeCorrection && !isPreview} onclick={ goNext } class="ui button" type="button">Suivant</button>
                    <button if={ modeCorrection && isPreview } onclick={ backToSubject } class="ui button" type="button">retour au sujet</button>
                </div>
            </div>
        </div>
    </form>

    <script>
        export default {
            loadQuestion(props){
                this.question = props.question.consigne;
                this.illustration = props.question.illustration;
                this.reponses = props.question.propositions;
                this.type = props.question.type;
            },
            onBeforeMount(props, state) {
                this.loadQuestion(props);
                this.localProps = props;
                this.correctionData = [];
                this.init_correction();
                this.modeCorrection = false;
                this.isPreview = props.isPreview;
            },
            onMounted(props, state) {
                $('.ui.form').form({
                    fields: {
                        reponse: {
                            identifier: 'reponse',
                            rules: [
                                {
                                    type: 'checked',
                                    prompt: 'Vous n\'avez pas selectionné de réponse'
                                }
                            ]
                        }
                    }
                });
                $('.ui.checkbox').checkbox();
                const illustration_element = $("#answer_illu");
                if(illustration_element.length) {
                    illustration_element[0].src = this.illustration;
                }
            },
            onBeforeUpdate(props, state) {
                this.loadQuestion(props);
            },
            init_correction(){
                for (const i in this.localProps.question.propositions) {
                    this.correctionData.push({class: null, checked: false});
                }
            },
            getCorrection(){
                const responses = this.localProps.question.numReponse.split(',');
                const goodResponses = this.localProps.question.bonneReponse.split(',');
                for (const i in this.localProps.question.propositions) {
                    const positionWithOffset = Number(i) + 1;
                    if(responses.indexOf(positionWithOffset.toString()) !== -1 && goodResponses.indexOf(positionWithOffset.toString()) !== -1) {
                        this.correctionData[i] = {class: 'good-response', checked: true};
                    } else if(goodResponses.indexOf(positionWithOffset.toString()) !== -1){
                        this.correctionData[i] = {class: 'good-proposition', checked: false};
                    } else if(responses.indexOf(positionWithOffset.toString()) !== -1 && goodResponses.indexOf(positionWithOffset.toString()) === -1) {
                        this.correctionData[i] = {class: 'wrong-response', checked: true};
                    }else {
                        this.correctionData[i] = {class: null, checked: false};
                    }
                }
            },
            isCorrectResponses(e) {
                if(this.localProps.question.type == 'QCU'){
                    const numResponseWithOffset = Number(e.target.reponse.value) + 1;
                    this.numReponse = numResponseWithOffset.toString();
                    this.localProps.question.numReponse = this.numReponse;
                    this.localProps.question.correct = this.numReponse === this.localProps.question.bonneReponse;
                } else {
                    const checkedInput = [];
                    for(let iResponse = 0; iResponse < e.target.reponse.length; iResponse++) {
                        if(e.target.reponse[iResponse].checked) checkedInput.push(iResponse + 1);
                    }
                    this.localProps.question.numReponse = checkedInput.join(',');
                    this.localProps.question.correct = this.localProps.question.numReponse === this.localProps.question.bonneReponse;
                }
            },
            submit(e, props) {
                e.preventDefault();
                if( $('.ui.form').form('is valid')) {
                    this.isCorrectResponses(e);
                    this.modeCorrection = true;
                    this.getCorrection();
                    this.update();
                }
            },
            goNext(e){
                e.preventDefault();
                this.localProps.eventBus.trigger('response', this.localProps.question);
            },
            backToSubject(e){
                e.preventDefault();
                window.location.href = '/';
            }
        }
    </script>
</question-cpn>