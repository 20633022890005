<duos-cpn>
    <div class="ui center aligned container">
        <h2>Choix d'un duo</h2>
    </div>
    <subject-duo-choice posssibilities="{duos}" clickHandler="{click}"></subject-duo-choice>

    <script>
        export default {
            onBeforeMount(props, state) {
                this.duos =  props.duos.slice(0, 2);
                this.localProps = props;
            },
            click(e) {
                e.preventDefault();
                this.choix=e.target.value;
                this.localProps.eventBus.trigger('duoEstChoisi', this.choix);
            }
        }
    </script>
</duos-cpn>